import { StoryData } from 'storyblok-js-client';
import { datadogRum } from '@datadog/browser-rum';
import { StoryDataFromGraphQLQuery } from '../../templates/types';
import { getCacheBuster } from '../../utils/get-cache-buster';
import { parseCludoEngineID } from '../../utils/parse-cludo-engine-id';
import { LanguageService } from '../language';
import { StoryblokService } from '../storyblok';
import { StringService } from '../string';
import { TeaserService } from '../teaser';
import { GlobalConfigProps } from './types';

export interface WindowWithOnetrust extends Window {
  OneTrust: {
    Close: () => void
    IsAlertBoxClosed: () => boolean
  };
}

type HTMLElementContent = string | { toString: () => string };
interface StoryDataWithLocaleInjection extends StoryData {
  lang: string;
  allSpaceLocales: string[];
}

const additionalWhiteListedDomains: string[] = ['assets.roche.com', 'assets.cwp.roche.com'];

/* Route is always the same: pages/topic/* */
const getTagPageUrl = (): string => 'topic';

const pageTypes: Record<string, string> = {
  page: 'Page',
  story: 'Story',
};

export const DomService = {
  createElement(tagName: string, content: HTMLElementContent = '', props: Record<string, string> = {}): HTMLElement {
    const element = document.createElement(tagName);
    element.innerHTML = typeof content === 'string' ? content : content.toString();
    Object.keys(props).forEach((prop) => {
      element.setAttribute(prop, props[prop]);
    });
    return element;
  },

  activateDatadogMonitoring(): void {
    if (process.env.GATSBY_DATADOG_APPLICATION_ID === 'default'
      || process.env.GATSBY_DATADOG_CLIENT_TOKEN === 'default'
      || process.env.GATSBY_DATADOG_SERVICE === 'default') {
      return;
    }

    datadogRum.init({
      applicationId: process.env.GATSBY_DATADOG_APPLICATION_ID,
      clientToken: process.env.GATSBY_DATADOG_CLIENT_TOKEN,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.eu',
      service: process.env.GATSBY_DATADOG_SERVICE,
      env: process.env.GATSBY_WEBSITE_STAGE || 'test',
      version: process.env.GATSBY_CURRENT_RELEASE_VERSION || 'local',
      sessionSampleRate: 0,
      sessionReplaySampleRate: 0,
      trackUserInteractions: false,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  },

  activateConsentScript(): void {
    const oneTrustScriptPlaceholder = document.getElementById('oneTrustScriptPlaceholder');
    if (!oneTrustScriptPlaceholder) {
      return;
    }

    const activatedOneTrustScript = document.createElement('script');
    const oneTrustScriptSettings = {
      src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      type: 'text/javascript',
      charSet: 'UTF-8',
      async: 'true',
      defer: 'true',
      'data-domain-script': `${process.env.GATSBY_ROCHE_ONETRUST_KEY}`,
      'data-document-language': 'true',
    };

    Object.entries(oneTrustScriptSettings)
      .forEach(([name, value]) => activatedOneTrustScript.setAttribute(name, value));
    document.head.replaceChild(activatedOneTrustScript, oneTrustScriptPlaceholder);
  },

  getParsedLocale(locale: string): string {
    return locale === 'default' ? LanguageService.getDefaultLocale() : locale;
  },

  getGlobalConfig(
    story: StoryDataFromGraphQLQuery,
    defaultLanguageHome?: StoryDataFromGraphQLQuery,
  ): GlobalConfigProps {
    const {
      id: storyblokStoryId,
      uuid: pageId,
      lang: locale,
      content,
      allSpaceLocales,
    } = story as StoryDataWithLocaleInjection;
    // TODO: Confirm tag page url should remain localised
    const tagPage = LanguageService.getLocaleAwareLink(getTagPageUrl(), locale);
    const websiteName = process.env.GATSBY_WEBSITE_NAME === 'debug-roche-ch-staging' ? 'roche-ch' : process.env.GATSBY_WEBSITE_NAME;

    const whiteListedDomains: string[] = [...additionalWhiteListedDomains];

    try {
      whiteListedDomains.push(JSON.parse(process.env.GATSBY_WHITE_LISTED_DOMAINS));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Parsing GATSBY_WHITE_LISTED_DOMAINS failed: ', error);
    }

    const alternates = story.alternates.map((alternate) => {
      const { fullSlug: graphQlApiSlug, full_slug: contentDeliveryApiSlug, published } = alternate;

      // Check if the slug includes '/topic/' and return the formatted locale and URL
      if (contentDeliveryApiSlug?.includes('/topic/')) {
        return {
          locale: LanguageService.getLocaleFromSlug(contentDeliveryApiSlug),
          url: contentDeliveryApiSlug,
        };
      }

      const isDefaultHome = defaultLanguageHome?.id === alternate.id;
      const slug = isDefaultHome ? LanguageService.getHomePageUrl(defaultLanguageHome)
        : (graphQlApiSlug || contentDeliveryApiSlug);

      if (published || StoryblokService.isInEditor()) {
        return {
          locale: LanguageService.getLocaleFromSlug(slug),
          url: slug,
        };
      }

      return undefined;
    }).filter(Boolean);

    return {
      allSpaceLocales,
      alternates,
      tagPageUrl: tagPage,
      baseDomain: process.env.GATSBY_BASE_DOMAIN,
      brightcoveAccountId: process.env.GATSBY_BRIGHTCOVE_ACCOUNT_ID,
      brightcovePlayerId: process.env.GATSBY_BRIGHTCOVE_PLAYER_ID,
      cludoCustomerID: process.env.GATSBY_CLUDO_CUSTOMER_ID,
      cludoEngineId:
        process.env.GATSBY_CLUDO_ENGINE_ID
        || parseCludoEngineID(process.env.GATSBY_CLUDO_ENGINE_ID_LIST, locale),
      cognitoUserpoolClientId: process.env.GATSBY_COGNITO_USERPOOL_CLIENT_ID,
      cognitoUserpoolId: process.env.GATSBY_COGNITO_USERPOOL_ID,
      formsApiUrl: process.env.GATSBY_FORMS_API_URL,
      headerIconUrl: StringService.trimUserInput(process.env.GATSBY_ROCHE_HEADER_ICON_URL),
      locale,
      nonDefaultThemeSubcategories: TeaserService.nonDefaultThemeSubcategories,
      pageId: `storyblok:${process.env.GATSBY_STORYBLOK_SPACE_API_KEY_NAME}:${pageId}`,
      recaptchaKey: process.env.GATSBY_GOOGLE_RECAPTCHA_KEY,
      translationUrl: `/translations/${locale}.json${getCacheBuster()}`,
      tagsTranslationUrl: `/tags-translations/${locale}.json${getCacheBuster()}`,
      popularSearchesTranslationUrl: `/popular-searches-translations/${locale}.json${getCacheBuster()}`,
      twitterHandle: fixTwitterHandle(process.env.GATSBY_TWITTER_HANDLE),
      websiteName,
      whiteListedDomains: JSON.stringify(whiteListedDomains),
      isShareHidden: content?.hide_share || false,
      gapiClientId: process.env.GATSBY_GAPI_CLIENT_ID,
      gapiKey: process.env.GATSBY_GAPI_KEY,
      // TODO: remove when the cluod API is moved to the Gatsby Repository
      cludoFuzziness: process.env.GATSBY_CLUDO_FUZZINESS,
      storyblokStoryId,
      componentLibrary: process.env.GATSBY_ROCHE_COMPONENTS_LIBRARY_URL,
    };
  },

  getPageCategory(componentName: string): string {
    return pageTypes[componentName];
  },

  getPageType(
    isHomePage: boolean,
    pageType: string,
  ): string {
    if (isHomePage) {
      return 'Home';
    }

    if (pageType === 'story') {
      return 'Story';
    }

    if (pageType === 'page') {
      return 'Page';
    }

    return 'Other';
  },
};

/**
 * EXTCWP-565: This ensures that the Twitter handle is always prefixed with '@' as by
 * Feb. 2025 datasource entries can no longer have a value that starts with '@'.
 *
 * @param {string} twitterHandle the user's twitter handle
 */
function fixTwitterHandle(twitterHandle: string): string {
  if (!twitterHandle || twitterHandle.length === 0) {
    return '';
  }
  if (!twitterHandle.startsWith('@')) {
    return `@${twitterHandle}`;
  }
  return twitterHandle;
}
